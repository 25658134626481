import React, {
  useEffect,
  useState,
  useMemo,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useForm, Controller } from "react-hook-form";
import TextAreaInput from "@/Components/TextAreaInput";
import { zodResolver } from "@hookform/resolvers/zod";
import SelectButton from "@/Components/Projects/SliderCard/SelectButton";
import { useParams } from "react-router-dom";
import { useUpdateSelectedOpportunity } from "@/Hooks/react-query/opportunity/useUpdateSelected";
import toast from "react-hot-toast";
import MarkdownView from "@/Components/MardownView";

import {
  IdeaPersonalization,
  IdeaPersonalizationContent,
} from "@/Types/idea_personalization";
import {
  IdeaPersonalizationSchema,
  Schema,
} from "@/Types/idea_personalization/schema";
import { useUpdateSelected } from "@/Hooks/react-query/IdeaPersonalization/useUpdateSelected";

const Container: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className = "" }) => (
  <div className={`flex flex-col gap-10 ` + className}>{children}</div>
);
const Label: React.FC<{
  value: string;
}> = ({ value }) => (
  <p className='text-12 leading-14 font-semibold text-black-redx'>{value}</p>
);
const Content: React.FC<{
  value: string;
  isFetching: boolean;
}> = ({ value, isFetching }) => {
  const isValueList = value?.split("\n").length > 1;
  if (isValueList) {
    return (
      <ul className='list-disc ms-20'>
        {value.split("\n").map((item, index) => (
          <li
            key={index}
            className='text-14 leading-20 font-normal text-black-redx'>
            {!isFetching && <MarkdownView content={item} />}
            {isFetching && (
              <div className='text-14 py-4 min-h-50'>
                <div className='w-full h-20 animate-pulse bg-soft-purple-redx rounded-full  mb-14' />
              </div>
            )}
          </li>
        ))}
      </ul>
    );
  }
  return (
    <div className='text-14 leading-20 font-normal text-black-redx inline'>
      {!isFetching && <MarkdownView content={value} />}
      {isFetching && (
        <div className='text-14 py-4 min-h-50'>
          <div className='w-full h-20 animate-pulse bg-soft-purple-redx rounded-full  mb-14' />
        </div>
      )}
    </div>
  );
};

const Card = forwardRef<
  any,
  {
    item?: IdeaPersonalization;
    archetypeId?: number;
    index: number;
    totalItem: number;
    isFetching?: boolean;
    isCanEdit?: boolean;
    isEditing?: boolean;
    isAdding?: boolean;
    onSaveEditedData?: (
      data: Omit<IdeaPersonalizationContent, "idea_personalization_number">,
      index: number,
      id: number,
      archetypeId: number,
    ) => void;
    onSuccessSelected?: (index: number) => void;
  }
>(
  (
    {
      item,
      index,
      archetypeId,
      totalItem,
      isFetching = false,
      isCanEdit = true,
      onSaveEditedData,
      isEditing,
      isAdding,
      onSuccessSelected,
    },
    ref,
  ) => {
    const defaultValues: Schema = useMemo(
      () => ({
        idea_title: item?.idea_personalization_content?.idea_title ?? "",
        idea_description:
          item?.idea_personalization_content?.idea_description ?? "",
        reason_product_brand:
          item?.idea_personalization_content?.reason_product_brand ?? "",
        reason_target_audience:
          item?.idea_personalization_content?.reason_target_audience ?? "",
      }),
      [item],
    );
    const params = useParams<{ project_slug: string }>();
    const [selectLoading, setSelectLoading] = useState(false);
    const { mutate } = useUpdateSelected();

    const { control, handleSubmit, reset, setError } = useForm<Schema>({
      resolver: zodResolver(IdeaPersonalizationSchema),
      defaultValues,
    });

    useImperativeHandle(
      ref,
      () => ({
        submitForm: () =>
          handleSubmit(async (formData) => {
            const result = await IdeaPersonalizationSchema.safeParse(formData);
            result.error?.errors.forEach((error) => {
              setError(error.path[0] as keyof Schema, {
                message: error.message,
              });
            });
            if (result.success) {
              onSaveEditedData &&
                onSaveEditedData(formData, index, item?.id ?? -1, archetypeId!);
            }
          })(),
      }),
      [handleSubmit, onSaveEditedData, index],
    );

    useEffect(() => {
      reset(item?.idea_personalization_content);
    }, [item, reset, isEditing]);

    const handleSelect = () => {
      setSelectLoading(true);
      mutate(
        {
          projectSlug: params.project_slug ?? "",
          id: item!.id,
        },
        {
          onSuccess: () => {
            setSelectLoading(false);
            onSuccessSelected && onSuccessSelected(index);
          },
          onError: () => {
            setSelectLoading(false);
            toast.error("Something went wrong");
          },
        },
      );
    };

    return (
      <div className='flex flex-col w-full pb-35 transition duration-500'>
        <div className='py-15 text-center '>
          <h1 className='text-16 leading-20 text-black-redx font-bold text-center w-full'>
            {!isFetching && (
              <div className='py-15 px-35 text-center inline-flex gap-8 justify-center items-center'>
                <h1 className='text-1620 font-bold'>
                  Idea Personalization {index + 1} of {totalItem}
                </h1>
                {(item?.is_edit || isAdding) && (
                  <div className='bg-soft-grey-redx px-8 py-4 text-grey-redx text-12 leading-14 rounded-8'>
                    Edited
                  </div>
                )}
              </div>
            )}

            {isFetching && (
              <h1 className='w-3/12 h-20 animate-pulse bg-soft-purple-redx rounded-full mx-auto mb-14' />
            )}
          </h1>
        </div>
        <form>
          <div
            className={`grid grid-cols-2 gap-24 pb-10 p-24 min-h-280 max-h-full  ${
              isEditing && item?.is_selected ? "!bg-transparent" : ""
            } ${
              item?.is_selected
                ? "border-t-2 border-blue-redx bg-softer-purple-redx"
                : "border-t-1 border-stroke-redx"
            }`}>
            <Container>
              <Label value='Idea Title' />
              {isEditing || isAdding ? (
                <Controller
                  name='idea_title'
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextAreaInput
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                      containerClassName='border-b-2 border-soft-purplestroke-redx text-black-redx'
                      className='mt-1 block w-full !text-14 resize-none overflow-custom'
                      rows={2}
                      placeholder='Input Idea Title here'
                    />
                  )}
                />
              ) : (
                <Content
                  isFetching={isFetching}
                  value={item?.idea_personalization_content?.idea_title ?? ""}
                />
              )}
            </Container>
            <Container>
              <Label value='Idea Description' />
              {isEditing || isAdding ? (
                <Controller
                  name='idea_description'
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextAreaInput
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                      containerClassName='border-b-2 border-soft-purplestroke-redx text-black-redx'
                      className='mt-1 block w-full !text-14 resize-none overflow-custom'
                      rows={2}
                      placeholder='Input the creative communication angle here'
                    />
                  )}
                />
              ) : (
                <Content
                  isFetching={isFetching}
                  value={
                    item?.idea_personalization_content?.idea_description ?? ""
                  }
                />
              )}
            </Container>
            <Container>
              <Label value='Reason Product Brand' />
              {isEditing || isAdding ? (
                <Controller
                  name='reason_product_brand'
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextAreaInput
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                      containerClassName='border-b-2 border-soft-purplestroke-redx text-black-redx'
                      className='mt-1 block w-full !text-14 resize-none overflow-custom'
                      rows={2}
                      placeholder='Input the features & benefits here'
                    />
                  )}
                />
              ) : (
                <Content
                  isFetching={isFetching}
                  value={
                    item?.idea_personalization_content?.reason_product_brand ??
                    ""
                  }
                />
              )}
            </Container>
            <Container>
              <Label value='Reason Target Audience' />
              {isEditing || isAdding ? (
                <Controller
                  name='reason_target_audience'
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextAreaInput
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                      containerClassName='border-b-2 border-soft-purplestroke-redx text-black-redx'
                      className='mt-1 block w-full !text-14 resize-none overflow-custom'
                      rows={2}
                      placeholder='Input the digital key touch points here'
                    />
                  )}
                />
              ) : (
                <Content
                  isFetching={isFetching}
                  value={
                    item?.idea_personalization_content
                      ?.reason_target_audience ?? ""
                  }
                />
              )}
            </Container>
          </div>
          <div className='flex items-center justify-end w-full py-20'>
            {!isFetching && isCanEdit && item && (
              <SelectButton
                isEditing={(isEditing || isAdding) as boolean}
                isSelected={item.is_selected}
                onSelect={handleSelect}
                isLoading={selectLoading}
                index={index}
              />
            )}
          </div>
        </form>
      </div>
    );
  },
);

export default Card;
