import React, { forwardRef, useContext, useState } from "react";
import { Icon } from "@iconify/react";

import TextInput from "@/Components/TextInput";
import InputError from "@/Components/InputError";
import InputLabel from "@/Components/InputLabel";
import InputCheckboxDropdown from "@/Components/InputCheckboxDropdown";
import { ProjectContext } from "@/Context/ProjectContext";
import { FieldErrors, UseFormSetValue } from "react-hook-form";
import { SubmissionFormPayload } from "@/Types/form";
import RegenerateModal from "@/Components/Modals/RegenerateModal";

interface AudienceArchetypeProps {
  data: {
    age_market_start: number | string;
    age_market_end: number | string;
    gender: string;
  };
  setData: UseFormSetValue<SubmissionFormPayload>;
  onEditSection: (sectionIndex: number) => Promise<boolean>;
  fetchData: () => void;
  errors: FieldErrors<SubmissionFormPayload>;
  setError: any;
  isActive: boolean;
  isFetching: boolean;
  genders: { title: string; value: string }[];
  isEditor: boolean;
}

const AudienceArchetype = forwardRef<HTMLDivElement, AudienceArchetypeProps>(
  (
    {
      data,
      setData,
      errors,
      setError,
      isActive,
      genders,
      onEditSection,
      fetchData,
      isEditor,
      isFetching,
    },
    ref,
  ) => {
    const [state] = useContext(ProjectContext);
    const [isEditing, setIsEditing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleAgeMarketStartChange = (
      e: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const value = e.target.value;
      if (
        value === "" ||
        (parseInt(value, 10) >= 0 && parseInt(value, 10) <= 100)
      ) {
        setData("age_market_start", value);
        setError("age_market_start", { message: "" });
        setError("age_market_end", { message: "" });
      }
    };

    const handleAgeMarketEndChange = (
      e: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const value = e.target.value;
      if (
        value === "" ||
        (parseInt(value, 10) >= 0 && parseInt(value, 10) <= 100)
      ) {
        setData("age_market_end", value);
        setError("age_market_start", { message: "" });
        setError("age_market_end", { message: "" });
      }
    };

    const submitForm = async () => {
      setIsModalOpen(false);
      const isNotError = await onEditSection(2);
      if (!isNotError) {
        setIsEditing(true);
        return;
      }
      setIsEditing(false);
    };

    return (
      <div
        ref={ref}
        className={`flex flex-col w-full mb-24 ${
          isActive ? "" : "opacity-30 cursor-not-allowed"
        }`}>
        <div className='flex justify-between items-center w-full'>
          <h1 className='font-bold text-24 leading-32 mb-15'>
            Target Audience
          </h1>
          {!isFetching &&
            state.submission.is_audience_information_complete &&
            !isEditing &&
            isActive &&
            isEditor && (
              <div
                className=' inline-flex gap-8 justify-center items-center cursor-pointer'
                onClick={() => setIsEditing(true)}>
                <span className='text-14 font-semibold text-blue-redx'>
                  Edit
                </span>
                <Icon
                  icon='lucide:pencil-line'
                  className='text-blue-redx w-16 h-16'
                />
              </div>
            )}
          {!isFetching && isEditing && isActive && isEditor && (
            <div className='inline-flex gap-8'>
              <button
                className='inline-flex items-center gap-x-4 text-error-redx cursor-pointer'
                onClick={() => {
                  fetchData();
                  setIsEditing(false);
                }}>
                <span className='text-15 font-semibold'>Cancel</span>
                <Icon
                  icon='lucide:x'
                  className='size-16'
                />
              </button>

              <button
                className='inline-flex items-center gap-x-4 cursor-pointer'
                onClick={() => setIsModalOpen(true)}>
                <p className='text-14 font-semibold text-blue-redx'>Save</p>
                <Icon
                  icon='lucide:save'
                  className='text-blue-redx size-16'
                />
              </button>
            </div>
          )}
        </div>
        <div className='grid grid-cols-2 w-full gap-24'>
          <div>
            <InputLabel
              htmlFor='age_market_start'
              value='Age Range'
              className='!text-black-redx'
            />
            <div className='inline-flex items-center justify-between w-full gap-x-10'>
              {isFetching ? (
                <div className='w-full h-20 animate-pulse bg-soft-purple-redx rounded-full  mt-8' />
              ) : (!state.submission.is_audience_information_complete ||
                  isEditing) &&
                isEditor ? (
                <TextInput
                  id='age_market_start'
                  type='number'
                  name='age_market_start'
                  value={data.age_market_start}
                  error={errors.age_market_start?.message}
                  containerClassName='border-b-2 border-soft-purplestroke-redx text-black-redx'
                  min={0}
                  max={100}
                  maxLength={3}
                  className='mt-1 block w-full !text-14'
                  placeholder='Input lower bound here'
                  disabled={!isActive}
                  isFocused={true}
                  onChange={handleAgeMarketStartChange}
                />
              ) : (
                <p className='!text-14 px-0 py-6 w-full'>
                  {!!data.age_market_start ? data.age_market_start : "-"}
                </p>
              )}

              <span className='text-black'>-</span>

              {isFetching ? (
                <div className='w-full h-20 animate-pulse bg-soft-purple-redx rounded-full  mt-8' />
              ) : (!state.submission.is_audience_information_complete ||
                  isEditing) &&
                isEditor ? (
                <TextInput
                  id='age_market_end'
                  type='number'
                  name='age_market_end'
                  value={data.age_market_end}
                  error={errors.age_market_end?.message}
                  containerClassName='border-b-2 border-soft-purplestroke-redx text-black-redx'
                  className='mt-1 block w-full !text-14'
                  min={0}
                  max={100}
                  maxLength={3}
                  placeholder='Input upper bound here'
                  disabled={!isActive}
                  isFocused={true}
                  onChange={handleAgeMarketEndChange}
                />
              ) : (
                <p className='!text-14 px-0 py-6 w-full'>
                  {!!data.age_market_end ? data.age_market_end : "-"}
                </p>
              )}
            </div>
            <div className='inline-flex items-center justify-between w-full gap-x-10 mt-2'>
              <p className=' w-full ml-2'>
                <InputError message={errors.age_market_start?.message} />
              </p>
              <p className=' w-full ml-5'>
                <InputError message={errors.age_market_end?.message} />
              </p>
            </div>
          </div>
          <div>
            <InputLabel
              htmlFor='gender'
              value='Gender'
              className='!text-black-redx'
            />
            {isFetching ? (
              <div className='w-full h-20 animate-pulse bg-soft-purple-redx rounded-full  mt-8' />
            ) : (!state.submission.is_audience_information_complete ||
                isEditing) &&
              isEditor ? (
              <InputCheckboxDropdown
                modelValue={data.gender}
                list={genders}
                state='init'
                multiple={true}
                error={errors.gender?.message}
                containerClassName='border-b-2 border-soft-purplestroke-redx text-black-redx'
                placeholder='Choose gender here'
                disabled={!isActive}
                onChange={(value: any) => {
                  setData("gender", value);
                  setError("gender", { message: "" });
                }}
              />
            ) : (
              <p className='!text-14 px-0 py-6'>
                {data.gender.length > 0 ? data.gender : "-"}
              </p>
            )}

            <InputError
              message={errors.gender?.message}
              className='mt-2'
            />
          </div>
        </div>
        <RegenerateModal
          isRegenerating={false}
          isOpen={isModalOpen}
          onClose={() => {
            fetchData();
            setIsEditing(false);
            setIsModalOpen(false);
          }}
          handleSubmit={submitForm}
        />
      </div>
    );
  },
);

export default AudienceArchetype;
