import React, { useContext, useEffect, useState, Fragment } from "react";
import { ProjectProps, SubsectionDataType } from "@/Types/projects";
import { ProjectContext } from "@/Context/ProjectContext";
import {
  saveInformation,
  saveRouteNext,
  saveRoutePrev,
  saveSubInformation,
} from "@/Context/actions/projectActions";
import GenerateLoading from "@/Components/GenerateLoading";
import SourcesList from "@/Components/Projects/SourcesList";
import RegenerateButton from "@/Components/Projects/RegenerateButton";
import Card from "./Partials/Card";
import { useGenerate } from "@/Context/hooks/useGenerate";
import { CompetitorAnalysisProps } from "@/Types/competitor_analysis";
import useRolesBadge from "@/Hooks/useRolesBadge";
import { historiesFilter } from "@/Helpers/filter";
import useHistoryStatus from "@/Hooks/react-query/useHistoryStatus";
import useHistoryLatest from "@/Hooks/react-query/useHistoryLatest";
import { progressPortion } from "@/Utils/dispatcher";
import { Helmet } from "react-helmet";
import useUser from "@/Hooks/react-query/useUser";
import { useNavigate } from "react-router-dom";
import { useUpdateHistory } from "@/Hooks/react-query/useUpdateHistory";
import { jsonPurify, stringToArray } from "@/Utils/string";
import { SectionList } from "@/Types/tabs";
import { RouteOption } from "@/Context/reducer/projectReducer";
import { useQueryClient } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";
import RegenerateModal from "@/Components/Modals/RegenerateModal";
import toast from "react-hot-toast";
import ErrorLLM from "@/Components/Toast/ErrorLLM";
import NotFoundCompetitor from "./Partials/NotFoundCompetitor";
import ViewInfoModal from "@/Components/Modals/ViewInfoModal";

const Index: React.FC<{ project: ProjectProps }> = ({ project }) => {
  const [state, dispatch] = useContext(ProjectContext);
  const [parsedData, setParsedData] = useState<CompetitorAnalysisProps[]>([]);
  const [_validatedData, setValidatedData] = useState<
    CompetitorAnalysisProps[]
  >([]);
  const maxGenerateLimit = project.max_generated_data;
  const navigate = useNavigate();
  const {
    isCompetitorAnalysisComplete,
    isOpportunityAmongCompetitionComplete,
    isPending: isPendingHistoryStatus,
  } = useHistoryStatus(project);
  const { data: user } = useUser();
  const { roles } = useRolesBadge(project, user);
  const { mutate } = useUpdateHistory();
  const queryClient = useQueryClient();
  const [isGenerate, setIsGenerate] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isRegenerating, setIsReGenerating] = useState(false);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);

  const isEditor = roles.includes("Owner") || roles.includes("Strategist");
  const total_regenerate: number = state.competitorAnalysis.data
    .total_regenerate
    ? state.competitorAnalysis.data.total_regenerate
    : 0;

  const routeOptionValue: RouteOption = {
    label: "Generate Opportunity Among Competition",
    isActive: true,
    isGenerate: true,
    onClick: () => {
      if (isCompetitorAnalysisComplete.isQuerying) {
        dispatch(
          saveSubInformation(
            `{text-error-redx} Please wait competitor is still generating`,
          ),
        );
        return;
      }

      checkSelectedArchetypeHasChanging();
    },
  };

  const checkSelectedArchetypeHasChanging = async () => {
    queryClient.resetQueries({
      queryKey: ["opportunities", project.slug],
      exact: true,
    });

    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: true }));
    const { data } = await fetcher.get(
      `/${project.slug}/competitor-analysis/check-regenerate`,
    );
    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));

    if (
      (data && data.data.has_new_archetype) ||
      isOpportunityAmongCompetitionComplete.isRegenerated
    ) {
      setShowRegenerateModal(
        data.data.has_new_archetype ||
          isOpportunityAmongCompetitionComplete.isRegenerated,
      );
      return;
    }

    navigate(`/${project.slug}/opportunity-among-competition`);
  };

  const {
    handleGenerateOpportunityAmongCompetition,
    handleGenerateCompetitorAnalysis,
    handleEditedCompetitorAnalysis,
    handleSetCompetitorAnalysisData,
  } = useGenerate(dispatch);

  const handleSetParsedData = () => {
    setIsError(false);
    try {
      const content = state.competitorAnalysis.data.content;
      if (content) {
        setParsedData(jsonPurify(content));
        setValidatedData(jsonPurify(content));
      }
    } catch (error) {
      setIsError(true);
    }
  };

  const handleEditChangedData = (
    data: CompetitorAnalysisProps,
    index: number,
  ) => {
    setValidatedData((prevData) => {
      const newData = [...prevData];
      newData[index] = data;
      return newData;
    });
  };

  const handleSaveEditedData = (
    data: CompetitorAnalysisProps,
    index: number,
  ) => {
    const mappedData = {
      ...data,
      features_benefits: stringToArray(data.features_benefits),
      key_touch_points: stringToArray(data.key_touch_points),
      opportunities: stringToArray(data.opportunities),
    };

    parsedData[index] = mappedData;
    handleEditedCompetitorAnalysis()(
      project.slug,
      state.competitorAnalysis.data.history_id,
      JSON.stringify(parsedData),
    );

    mutate({
      payload: JSON.stringify(parsedData),
      historyId: state.competitorAnalysis.data.history_id,
      projectSlug: project.slug,
    });
  };

  const handleRegenerateButton = async (prompt: string) => {
    setIsGenerate(true);
    setParsedData([]);
    await handleGenerateCompetitorAnalysis()(project.slug, prompt);
  };

  const handleRegenerateOpportunityButton = async () => {
    setIsReGenerating(true);
    await handleGenerateOpportunityAmongCompetition()(project.slug, null);
    navigate(`/${project.slug}/opportunity-among-competition`);
  };

  const [showViewInfoModal, setShowViewInfoModal] = useState(false);
  const { data, isLoading, refetch } = useHistoryLatest(project);

  useEffect(() => {
    // TODO: check if roles is not includes View Only
    const competitorAnalysis = historiesFilter({
      data,
      section: "competitor_analysis",
      subSection: null,
    });
    if (competitorAnalysis?.history_id) {
      handleSetCompetitorAnalysisData()(
        competitorAnalysis as SubsectionDataType,
      );
    } else if (
      !isLoading &&
      !isPendingHistoryStatus &&
      isCompetitorAnalysisComplete.status === false
    ) {
      setIsGenerate(true);
      if (!isCompetitorAnalysisComplete.isQuerying)
        handleGenerateCompetitorAnalysis()(project.slug);
    }
  }, [data, isCompetitorAnalysisComplete.isQuerying]);

  useEffect(() => {
    dispatch(saveRouteNext(routeOptionValue));
    dispatch(
      saveRoutePrev({
        label: "Back",
        isActive: true,
        onClick: () => {
          navigate(`/${project.slug}/challenge-and-communication-task`);
        },
      }),
    );
    dispatch(saveInformation(""));
    dispatch(saveSubInformation(""));
  }, []);

  useEffect(() => {
    isCompetitorAnalysisComplete.status &&
      dispatch(
        saveRouteNext({
          ...routeOptionValue,
          isInactive: isGenerate ? true : false,
          isDisabled: isError,
        }),
      );
  }, [isError, isCompetitorAnalysisComplete, isGenerate]);

  useEffect(() => {
    if (
      !isCompetitorAnalysisComplete.isQuerying &&
      isCompetitorAnalysisComplete.status
    ) {
      refetch();
    }

    if (!isPendingHistoryStatus && isCompetitorAnalysisComplete.isQuerying) {
      setIsGenerate(true);
    }

    if (
      isCompetitorAnalysisComplete.status &&
      !isCompetitorAnalysisComplete.isQuerying &&
      parsedData.length
    ) {
      setIsGenerate(false);
    }

    if (isCompetitorAnalysisComplete.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }
  }, [isCompetitorAnalysisComplete, parsedData, isPendingHistoryStatus]);

  useEffect(() => {
    handleSetParsedData();
  }, [state.competitorAnalysis.data]);

  useEffect(() => {
    if (isEditor) {
      routeOptionValue.isInactive =
        isGenerate ||
        isCompetitorAnalysisComplete.isQuerying ||
        !isCompetitorAnalysisComplete.status
          ? true
          : false;

      if (isOpportunityAmongCompetitionComplete.isRegenerated) {
        routeOptionValue.label = "Regenerate Opportunity Among Competition";
        routeOptionValue.isGenerate = true;
      }

      if (
        isOpportunityAmongCompetitionComplete.status &&
        !isOpportunityAmongCompetitionComplete.isRegenerated
      ) {
        routeOptionValue.label = "Next to Opportunity Among Competition";
        routeOptionValue.isGenerate = false;
      }

      if (
        !isOpportunityAmongCompetitionComplete.status &&
        !isOpportunityAmongCompetitionComplete.isRegenerated
      ) {
        routeOptionValue.label = " Generate Opportunity Among Competition";
        routeOptionValue.isGenerate = true;
      }

      routeOptionValue.onClick = () => {
        if (isCompetitorAnalysisComplete.isQuerying) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please wait competitor is still generating`,
            ),
          );
          return;
        }

        if (isOpportunityAmongCompetitionComplete.isQuerying) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please wait, idea is still generating`,
            ),
          );

          setTimeout(() => {
            dispatch(saveSubInformation(""));
          }, 3000);
          return;
        }

        checkSelectedArchetypeHasChanging();
      };
    } else {
      routeOptionValue.label = "Next to Opportunity Among Competition";
      routeOptionValue.isGenerate = false;
      routeOptionValue.isInactive =
        !isOpportunityAmongCompetitionComplete.status ||
        isOpportunityAmongCompetitionComplete.isQuerying;

      routeOptionValue.onClick = () => {
        if (isCompetitorAnalysisComplete.isQuerying) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please wait competitor is still generating`,
            ),
          );
          return;
        }

        if (!isOpportunityAmongCompetitionComplete.status) {
          setShowViewInfoModal(true);
          return;
        }

        navigate(`/${project.slug}/opportunity-among-competition`);
      };
    }

    dispatch(saveRouteNext({ ...routeOptionValue }));
  }, [
    isOpportunityAmongCompetitionComplete,
    isGenerate,
    isEditor,
    isCompetitorAnalysisComplete,
  ]);

  const sectionList: SectionList = {
    title: "Competitor Analysis",
    value: "",
    section: "competitor_analysis",
  };

  return (
    <Fragment>
      <Helmet>
        <title>Competitor Analysis</title>
      </Helmet>
      {isGenerate ? (
        <GenerateLoading
          progress={progressPortion({
            progress: Number(
              (state.competitorAnalysis.progress / 2).toFixed(0),
            ),
            isQuerying: isCompetitorAnalysisComplete.isQuerying,
            isContentAvailable: parsedData?.length > 0,
          })}
          project={project}
          section={sectionList}
        />
      ) : (
        <div className='w-full h-full'>
          <div className='flex items-center justify-between sticky top-56 z-20 bg-white py-16'>
            <div className='flex flex-col w-full'>
              <h1 className='text-25 font-bold mb-8 leading-none'>
                Competitor Analysis
              </h1>
              <span className='text-15 font-normal text-grey-redx'>
                Please review and edit this information, considering that the
                provided reference may not always be accurate.
              </span>
            </div>
            {isEditor && !isError && (
              <RegenerateButton
                onSubmit={(form) => handleRegenerateButton(form.prompt)}
                limit={total_regenerate}
                maxLimit={maxGenerateLimit}
              />
            )}
          </div>

          {!isLoading && isError ? (
            <NotFoundCompetitor project={project} />
          ) : (
            <>
              {!isLoading && !isCompetitorAnalysisComplete.isQuerying ? (
                <>
                  {parsedData.map((item, index) => (
                    <Card
                      key={index}
                      index={index}
                      item={item}
                      onSaveEditedData={handleSaveEditedData}
                      isCanEdit={isEditor}
                    />
                  ))}
                </>
              ) : (
                <Card
                  index={-1}
                  item={{
                    brand_name: "",
                    product_name: "",
                    communication_angle: "",
                    communication_idea: "",
                    communication_tone: "",
                    features_benefits: "",
                    key_touch_points: "",
                    opportunities: "",
                  }}
                  onSaveEditedData={handleSaveEditedData}
                  isCanEdit={isEditor}
                  isFetching={true}
                />
              )}

              <div className='flex w-full pb-60 mr-1'>
                {!isLoading && state.competitorAnalysis.data.sources && (
                  <SourcesList
                    sources={state.competitorAnalysis.data.sources}
                  />
                )}
                {isLoading && (
                  <div className='inline-flex gap-10'>
                    {Array.from({ length: 3 }).map((_, index) => (
                      <div
                        key={index}
                        className='min-w-363 h-65 flex items-center px-15 py-12 gap-x-12 border-1 border-soft-purple-redx rounded-10 full animate-pulse bg-soft-purple-redx'
                      />
                    ))}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      )}

      <RegenerateModal
        isRegenerating={isRegenerating}
        isOpen={showRegenerateModal}
        handleSubmit={handleRegenerateOpportunityButton}
        onClose={() => setShowRegenerateModal(false)}
      />

      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </Fragment>
  );
};

export default Index;
