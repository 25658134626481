import { Fragment, useContext, useEffect, useState } from "react";
import { ProjectContext } from "@/Context/ProjectContext";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { mainSchema, Schema } from "@/Types/creative-idea/schema";
import { CreativeIdea } from "@/Types/creative-idea/creative-idea";
import CardInput from "./Partials/CardInput";
import { Icon } from "@iconify/react/dist/iconify.js";
import EditButton from "@/Components/Projects/EditButton";
import { RouteOption } from "@/Context/reducer/projectReducer";
import {
  saveInformation,
  saveRouteNext,
  saveRoutePrev,
  saveSubInformation,
} from "@/Context/actions/projectActions";
import { useNavigate } from "react-router-dom";
import { ProjectProps } from "@/Types/projects";
import {
  useIdeaCreative,
  useUpdateCreative,
} from "@/Hooks/react-query/idea-creative";
import useRolesBadge from "@/Hooks/useRolesBadge";
import useUser from "@/Hooks/react-query/useUser";
import { useGenerate } from "@/Context/hooks/useGenerate";
import useHistoryStatus from "@/Hooks/react-query/useHistoryStatus";
import RegenerateModal from "@/Components/Modals/RegenerateModal";
import ViewInfoModal from "@/Components/Modals/ViewInfoModal";

const Index: React.FC<{ project: ProjectProps }> = ({ project }) => {
  const navigate = useNavigate();
  const [showViewInfoModal, setShowViewInfoModal] = useState(false);
  const [_, dispatch] = useContext(ProjectContext);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [disabledSave, setDisabledSave] = useState<boolean>(true);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("new");
  const { data, refetch, isPending, isFetching } = useIdeaCreative(
    project.slug,
  );
  const { mutate, isPending: isSubmitting } = useUpdateCreative();
  const { isIdeaAlignment, isPending: isPendingHistory } =
    useHistoryStatus(project);
  const { handleGenerateIdeaAlignment } = useGenerate(dispatch);

  const toggleEditing = () => setIsEditing(!isEditing);

  const { data: user } = useUser();
  const { roles } = useRolesBadge(project, user);
  const isEditor = roles.includes("Owner") || roles.includes("Creative");
  const [parsedData, setParsedData] = useState<CreativeIdea>({
    title: "",
    description: "",
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<Schema>({
    defaultValues: parsedData,
    resolver: zodResolver(mainSchema),
    mode: "onChange",
  });

  useEffect(() => {
    setDisabledSave(!isValid || isSubmitting);
    dispatch(
      saveRouteNext({
        ...routeOptionValue,
        isDisabled: !isValid || isSubmitting,
      }),
    );
  }, [isValid, isSubmitting]);

  const handleCancelEditSection = () => {
    toggleEditing();
    reset(parsedData);
  };

  const onSubmit = (data: Schema) => {
    mutate(
      { projectSlug: project.slug, payload: data },
      {
        onSuccess: async () => {
          refetch();
          isEditing && toggleEditing();

          if (!isPendingHistory && !isIdeaAlignment.isQuerying) {
            if (isIdeaAlignment.isRegenerated)
              await handleGenerateIdeaAlignment()(project.slug, null, "");
            else handleGenerateIdeaAlignment()(project.slug);
            navigate(`/${project.slug}/idea-alignment`);
          }
        },
      },
    );
  };

  const routeOptionValue: RouteOption = {
    label: "Generate Idea Alignment",
    isActive: true,
    isGenerate: isEditor ? true : false,
    isDisabled: disabledSave,
    onClick: () => {},
  };

  useEffect(() => {
    dispatch(saveRouteNext(routeOptionValue));
    dispatch(
      saveRoutePrev({
        label: "Back",
        isActive: true,
        onClick: () => navigate(`/${project.slug}/key-touch-point`),
      }),
    );
    dispatch(saveInformation(""));
    dispatch(saveSubInformation(""));
  }, [dispatch, navigate, project.slug]);

  useEffect(() => {
    if (data?.status) {
      if (data.data) {
        const { title, description } = data.data;

        reset({
          title: title,
          description: description,
        });

        setParsedData({
          title: title ?? "",
          description: description ?? "",
        });

        setIsEditing(false);
        setStatus(data?.is_idea_alignment_exists ? "next" : "generate");
      } else {
        isEditor && setIsEditing(true);
      }
    }
  }, [data]);

  useEffect(() => {
    let labelButton =
      status === "next" ? "Next to Idea Alignment" : "Generate Idea Alignment";

    let isGenerate = isEditor && status !== "next";
    if (isIdeaAlignment.isRegenerated) {
      labelButton = "Regenerate Idea Alignment";
      isGenerate = true;
    }

    if (!isEditor) {
      labelButton = "Next to Idea Alignment";
      isGenerate = false;
      routeOptionValue.isDisabled = false;
    }

    routeOptionValue.label = labelButton;
    routeOptionValue.isGenerate = isGenerate;
    routeOptionValue.onClick = async () => {
      if (!isEditor) {
        if (!isIdeaAlignment.status) {
          setShowViewInfoModal(true);
          return;
        }

        navigate(`/${project.slug}/idea-alignment`);
        return;
      }

      if (isEditor && isIdeaAlignment.isQuerying) {
        dispatch(
          saveSubInformation(
            `{text-error-redx} Please wait, idea alignment is still generating`,
          ),
        );

        setTimeout(() => {
          dispatch(saveSubInformation(""));
        }, 3000);

        return;
      }
      if (status === "next" && !isIdeaAlignment.isRegenerated) {
        navigate(`/${project.slug}/idea-alignment`);
      } else {
        await handleSubmit(onSubmit)();
      }
    };

    dispatch(
      saveRouteNext({
        ...routeOptionValue,
      }),
    );
  }, [status, disabledSave, isEditor, isIdeaAlignment]);

  const isNew = status === "new";

  return (
    <>
      <div className='w-full h-full'>
        <div className='w-full sticky top-56 z-20 bg-white py-16'>
          <h1 className='text-25 font-bold mb-8 leading-none'>Creative Idea</h1>
          <span className='text-15 font-normal text-grey-redx'>
            Please provide your initial ideas, which will be aligned with the
            strategy and expanded based on your selected touch points.
          </span>
        </div>
        <div className='flex flex-col gap-24 mt-35'>
          <Controller
            control={control}
            name='title'
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <CardInput
                  isEditing={isEditing}
                  value={value}
                  isFetching={isPending || isFetching}
                  defaultValue={parsedData.title}
                  error={error?.message}
                  placeholder='Input your idea title here'
                  title='Idea Title'
                  showTitle={true}
                  rows={1}
                  onChange={onChange}
                />
              );
            }}
          />
          <Controller
            control={control}
            name='description'
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <CardInput
                  isEditing={isEditing}
                  value={value}
                  isFetching={isPending || isFetching}
                  defaultValue={parsedData.description}
                  error={error?.message}
                  placeholder='Input your idea description here'
                  title='Idea Description'
                  showTitle={true}
                  rows={3}
                  onChange={onChange}
                />
              );
            }}
          />
        </div>
        {isEditor && !isNew && (
          <div
            className={`flex w-full justify-end items-center gap-x-15 mt-16`}>
            {isPending || isFetching ? (
              <div className='w-65 h-20 animate-pulse bg-soft-purple-redx rounded-full  mb-14' />
            ) : isEditing ? (
              <Fragment>
                <button
                  className='inline-flex items-center gap-x-4 text-error-redx cursor-pointer'
                  onClick={handleCancelEditSection}>
                  <span className='text-15 font-semibold'>Cancel</span>
                  <Icon
                    icon='lucide:x'
                    className='w-20 h-20'
                  />
                </button>
                <button
                  className={`inline-flex items-center gap-x-4 ${
                    disabledSave ? "text-gray-500" : "text-blue-redx"
                  } cursor-pointer`}
                  onClick={() => setIsModalOpen(true)}
                  disabled={disabledSave}>
                  <span className='text-15 font-semibold'>Save</span>
                  <Icon
                    icon='lucide:save'
                    className='w-20 h-20'
                  />
                </button>
              </Fragment>
            ) : (
              <EditButton toggleEditing={toggleEditing} />
            )}
          </div>
        )}
      </div>

      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
      <RegenerateModal
        isRegenerating={isSubmitting}
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        handleSubmit={async () => {
          setIsModalOpen(false);
          await handleSubmit(onSubmit)();
        }}
      />
    </>
  );
};

export default Index;
