import React, { Fragment, useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import GenerateLoading from "@/Components/GenerateLoading";
import DownloadButton from "@/Components/Projects/DownloadButton";
import CardInput from "./Partials/CardInput";
import { ProjectContext } from "@/Context/ProjectContext";
import {
  saveInformation,
  saveRouteNext,
  saveRoutePrev,
  saveSubInformation,
} from "@/Context/actions/projectActions";
import { ProjectProps } from "@/Types/projects";
import SectionCard from "./Partials/SectionCard";
import { useGenerate } from "@/Context/hooks/useGenerate";

import useHistoryStatus from "@/Hooks/react-query/useHistoryStatus";
import { progressPortion } from "@/Utils/dispatcher";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import useOnePageStrategy from "@/Hooks/react-query/one-page-strategy/useOnePageStrategy";
import { mainSchema, Schema } from "@/Types/one-page/schema";
import { OnePageStrategy } from "@/Types/one-page/one-page-strategy";
import { useUpdateOnePage } from "@/Hooks/react-query/one-page-strategy";
import SelectingFunnel from "./Partials/SelectingFunnel";
import { SectionList } from "@/Types/tabs";
import useDifferentArchetypes from "@/Hooks/react-query/audience-archetype/useDifferentArchetypes";
import RegenerateModal from "@/Components/Modals/RegenerateModal";
import toast from "react-hot-toast";
import ErrorLLM from "@/Components/Toast/ErrorLLM";
import DownloadPDFButton from "./Partials/DownloadPDFButton";
import useRolesBadge from "@/Hooks/useRolesBadge";
import useUser from "@/Hooks/react-query/useUser";
import ViewInfoModal from "@/Components/Modals/ViewInfoModal";

// Define Zod schema

const Index: React.FC<{ project: ProjectProps }> = ({ project }) => {
  const [state, dispatch] = useContext(ProjectContext);
  const [parsedData, setParsedData] = useState<OnePageStrategy>({
    campaign_objective: "",
    challenge: "",
    communication_task: "",
    selected_audience_archetypes: [],
  });

  const [isGenerate, setIsGenerate] = useState<boolean>(false);
  const { mutate, isPending } = useUpdateOnePage();

  const { handleGenerateOnePageStrategy, handleGenerateKeyTouchPoint } =
    useGenerate(dispatch);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);

  const {
    isOnePageStrategy,
    isKeyTouchPoint,
    isUpdateState: isFetchingHistoryStatus,
    isFetched: isFetchedHistory,
  } = useHistoryStatus(project);

  const { data: user } = useUser();
  const { roles } = useRolesBadge(project, user);

  const { data, isLoading, refetch, isRefetching, isFetching } =
    useOnePageStrategy(project);
  const { data: differentArchetypes, isPending: isPendingDiffArch } =
    useDifferentArchetypes(project);

  const [disabledSave, setDisabledSave] = useState<boolean>(true);
  const [isRegeneratingModal, setIsRegeneratingModal] =
    useState<boolean>(false);
  const [modalFramework, setModalFramework] = useState<boolean>(false);
  const navigate = useNavigate();
  const isEditor = roles.includes("Owner") || roles.includes("Strategist");
  const [showViewInfoModal, setShowViewInfoModal] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<Schema>({
    defaultValues: parsedData,
    resolver: zodResolver(mainSchema),
  });

  watch(async (data) => {
    const result = await mainSchema.safeParse(data);
    result.error?.errors.forEach((error) => {
      setError(error.path[0] as keyof OnePageStrategy, {
        message: error.message,
      });
    });
    if (result.success) {
      clearErrors();
      setDisabledSave(false);
    } else {
      setDisabledSave(true);
    }
  });

  useEffect(() => {
    if (
      !isPendingDiffArch &&
      !differentArchetypes?.is_different_archetype.one_pages &&
      data &&
      data.data
    ) {
      const {
        campaign_strategic_statement,
        challenge,
        communication_task,
        selected_audience_archetypes,
        is_querying,
      } = data.data;

      if (is_querying) {
        setIsGenerate(true);
        setParsedData({
          campaign_objective: "",
          challenge: "",
          communication_task: "",
          selected_audience_archetypes: [],
        });
        return;
      }

      setParsedData({
        campaign_objective: campaign_strategic_statement,
        challenge,
        communication_task,
        selected_audience_archetypes,
      });
      dispatch(saveSubInformation(""));
    }
  }, [data, differentArchetypes, isPendingDiffArch, isGenerate]);

  useEffect(() => {
    !isFetchingHistoryStatus && !isOnePageStrategy.isQuerying && refetch();
  }, [isOnePageStrategy.isQuerying, isFetchingHistoryStatus, isGenerate]);

  useEffect(() => {
    if (
      isOnePageStrategy.status &&
      !isOnePageStrategy.isQuerying &&
      isFetchedHistory &&
      parsedData &&
      parsedData.challenge &&
      parsedData.communication_task
    ) {
      setTimeout(() => {
        setIsGenerate(false);
      }, 1000);
    }

    if (isOnePageStrategy.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }
  }, [
    isOnePageStrategy,
    parsedData,
    isFetchingHistoryStatus,
    isFetchedHistory,
  ]);

  useEffect(() => {
    if (
      !isPendingDiffArch &&
      differentArchetypes &&
      differentArchetypes.is_different_archetype.one_pages
    ) {
      if (
        !isGenerate &&
        !isFetchingHistoryStatus &&
        !isOnePageStrategy.isQuerying
      ) {
        setIsGenerate(true);
        setParsedData({
          campaign_objective: "",
          challenge: "",
          communication_task: "",
          selected_audience_archetypes: [],
        });

        dispatch(saveInformation(""));
        dispatch(saveSubInformation(""));
        handleGenerateOnePageStrategy()(project.slug);
      }
    }
  }, [
    isGenerate,
    differentArchetypes,
    isOnePageStrategy,
    isFetchingHistoryStatus,
    isPendingDiffArch,
  ]);

  useEffect(() => {
    reset(parsedData);
  }, [parsedData]);

  useEffect(() => {
    dispatch(
      saveRoutePrev({
        label: "Back",
        isActive: true,
        onClick: () => {
          navigate(`/${project.slug}/opportunity-among-competition`);
        },
      }),
    );
    dispatch(saveInformation(""));
  }, [dispatch, navigate, project.slug]);

  useEffect(() => {
    let label = "Generate Key Touch Point";
    let isGenerateButton = true;
    let isInactive = true;
    if (isEditor) {
      isInactive =
        isGenerate || isOnePageStrategy.isQuerying || !isOnePageStrategy.status
          ? true
          : false;
      if (
        isKeyTouchPoint.status &&
        !isKeyTouchPoint.isRegenerated &&
        !differentArchetypes?.is_different_archetype.key_touch_points
      ) {
        label = "Next to Key Touch Point";
        isGenerateButton = false;
      }

      if (
        isKeyTouchPoint.status &&
        (isKeyTouchPoint.isRegenerated ||
          differentArchetypes?.is_different_archetype.key_touch_points)
      ) {
        label = "Regenerate Key Touch Point";
      }
    } else {
      label = "Next to Key Touch Point";
      isGenerateButton = false;
      isInactive = !isKeyTouchPoint.status || isKeyTouchPoint.isQuerying;
    }

    dispatch(saveSubInformation(""));

    dispatch(
      saveRouteNext({
        label,
        isActive: true,
        isInactive: isInactive,
        isGenerate: isGenerateButton,
        onClick: () => {
          if (
            !isOnePageStrategy.status ||
            isGenerate ||
            isOnePageStrategy.isQuerying
          ) {
            dispatch(
              saveSubInformation(
                `{text-error-redx} Please wait, one page strategy is still generating`,
              ),
            );
            return;
          }

          if (!isKeyTouchPoint.status && !isEditor) {
            setShowViewInfoModal(true);
            return;
          }

          if (!isPendingDiffArch && isEditor) {
            if (isKeyTouchPoint.isQuerying) {
              dispatch(
                saveSubInformation(
                  `{text-error-redx} Please wait, key touch point is still generating`,
                ),
              );

              setTimeout(() => {
                dispatch(saveSubInformation(""));
              }, 3000);
              return;
            }

            if (differentArchetypes?.is_different_archetype.key_touch_points) {
              setModalFramework(true);
              return;
            }
            if (isKeyTouchPoint.isRegenerated) {
              setShowRegenerateModal(true);
              return;
            }

            navigate(`/${project.slug}/key-touch-point`);
            return;
          }
          navigate(`/${project.slug}/key-touch-point`);
        },
      }),
    );
  }, [
    isEditor,
    dispatch,
    navigate,
    project.slug,
    isPendingDiffArch,
    differentArchetypes?.is_different_archetype.key_touch_points,
    isOnePageStrategy,
    isGenerate,
    isKeyTouchPoint,
  ]);

  const handleCancelEditSection = () => {
    reset(parsedData);
  };

  const handleRegenerateKeyTouchPoint = async () => {
    setIsRegeneratingModal(true);
    await handleGenerateKeyTouchPoint()(project.slug, null);
    setIsRegeneratingModal(false);
    setShowRegenerateModal(false);
    navigate(`/${project.slug}/key-touch-point`);
  };

  const onSubmit = async (data: Schema) => {
    const projectSlug = project.slug;
    mutate(
      { payload: data, projectSlug },
      {
        onSuccess: () => {
          refetch();
        },
      },
    );
  };

  const sectionList: SectionList = {
    title: "One Page Strategy",
    value: "onepage",
    section: "brand",
  };

  return (
    <Fragment>
      <Helmet>
        <title>One-Page Strategy</title>
      </Helmet>
      {isGenerate ? (
        <GenerateLoading
          progress={progressPortion({
            progress: Number((state.onePageStrategy.progress / 2).toFixed(0)),
            isQuerying: isOnePageStrategy.isQuerying,
            isContentAvailable: true,
          })}
          project={project}
          section={sectionList}
        />
      ) : (
        <div className='w-full h-full'>
          <div className='flex justify-between items-center sticky top-56 z-20 bg-white py-16'>
            <div className='flex flex-col'>
              <h1 className='text-25 font-bold mb-8 leading-none'>
                One-Page Strategy
              </h1>
              <span className='text-15 font-normal text-grey-redx'>
                Please review and edit this information, considering that the
                provided reference may not always be accurate.
              </span>
            </div>
            <div className='flex items-center'>
              <DownloadPDFButton
                isFetching={isLoading || isOnePageStrategy.isQuerying}
                parsedData={parsedData}
                project={project}
              />
            </div>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='flex flex-col w-full gap-y-35 pb-60'>
            <SectionCard
              isEditor={isEditor}
              disabledSave={disabledSave || isPending}
              title='Campaign Objective'
              cols={1}
              isFetching={isLoading || isOnePageStrategy.isQuerying}
              initialEditState={
                parsedData.campaign_objective?.trim()?.length === 0
              }
              onCancelEdit={handleCancelEditSection}
              onSubmitEdit={handleSubmit(onSubmit)}>
              {({ isEditing }) => (
                <Controller
                  control={control}
                  name='campaign_objective'
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <CardInput
                        isEditing={isEditing}
                        value={value}
                        isFetching={isLoading || isOnePageStrategy.isQuerying}
                        defaultValue={parsedData.campaign_objective}
                        error={error?.message}
                        showTitle={false}
                        placeholder='Input the campaign objective here'
                        title='Campaign Objective'
                        onChange={onChange}
                      />
                    );
                  }}
                />
              )}
            </SectionCard>

            <SectionCard
              isEditor={isEditor}
              disabledSave={disabledSave || isPending}
              title='Challenge'
              cols={1}
              isFetching={isLoading || isOnePageStrategy.isQuerying}
              onCancelEdit={handleCancelEditSection}
              onSubmitEdit={handleSubmit(onSubmit)}>
              {({ isEditing }) => (
                <Controller
                  control={control}
                  name='challenge'
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <CardInput
                        isEditing={isEditing}
                        value={value}
                        isFetching={isLoading || isOnePageStrategy.isQuerying}
                        defaultValue={parsedData.challenge}
                        error={error?.message}
                        placeholder='Input challenge here'
                        title='Challenge'
                        onChange={onChange}
                        showTitle={false}
                      />
                    );
                  }}
                />
              )}
            </SectionCard>

            <SectionCard
              isEditor={isEditor}
              disabledSave={disabledSave || isPending}
              title='Communication Task'
              cols={1}
              isFetching={isLoading || isOnePageStrategy.isQuerying}
              onCancelEdit={handleCancelEditSection}
              onSubmitEdit={handleSubmit(onSubmit)}>
              {({ isEditing }) => (
                <Controller
                  control={control}
                  name='communication_task'
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <CardInput
                        isEditing={isEditing}
                        value={value}
                        isFetching={isLoading || isOnePageStrategy.isQuerying}
                        defaultValue={parsedData.communication_task}
                        error={error?.message}
                        placeholder='Input the communication task here'
                        title='Communication Task'
                        showTitle={false}
                        onChange={onChange}
                      />
                    );
                  }}
                />
              )}
            </SectionCard>

            <SectionCard
              isEditor={isEditor}
              disabledSave={disabledSave || isPending}
              title='Audience Insights'
              cols={2}
              isFetching={isLoading || isOnePageStrategy.isQuerying}
              onCancelEdit={handleCancelEditSection}
              onSubmitEdit={handleSubmit(onSubmit)}>
              {({ isEditing, gridColumnClass }) => (
                <Fragment>
                  {!isLoading && !isOnePageStrategy.isQuerying
                    ? parsedData.selected_audience_archetypes.map(
                        (item, index) => (
                          <Fragment key={index}>
                            <h3 className='text-15 font-bold pb-4 text-blue-redx'>
                              {`Archetype ${index + 1} - ${
                                item.archetype_name || ""
                              }`}
                            </h3>
                            <div
                              className={`grid ${gridColumnClass} gap-x-24 gap-y-15 pb-8`}>
                              <Controller
                                control={control}
                                name={`selected_audience_archetypes.${index}.onepage_content.audience_insights.pain_point`}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => {
                                  return (
                                    <CardInput
                                      isEditing={isEditing}
                                      value={value?.toString()}
                                      defaultValue={
                                        item.onepage_content.audience_insights
                                          .pain_point
                                      }
                                      error={error?.message}
                                      placeholder='Input pain point here'
                                      title='Pain Point'
                                      showTitle={true}
                                      onChange={onChange}
                                    />
                                  );
                                }}
                              />

                              <Controller
                                control={control}
                                name={`selected_audience_archetypes.${index}.onepage_content.audience_insights.lifestyle`}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => {
                                  return (
                                    <CardInput
                                      isEditing={isEditing}
                                      value={value?.toString()}
                                      defaultValue={
                                        item.onepage_content.audience_insights
                                          .lifestyle
                                      }
                                      error={error?.message}
                                      placeholder='Input lifestyle here'
                                      title='Lifestyle'
                                      showTitle={true}
                                      onChange={onChange}
                                    />
                                  );
                                }}
                              />

                              <Controller
                                control={control}
                                name={`selected_audience_archetypes.${index}.onepage_content.audience_insights.behavioral`}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => {
                                  return (
                                    <CardInput
                                      isEditing={isEditing}
                                      value={value?.toString()}
                                      defaultValue={
                                        item.onepage_content.audience_insights
                                          .behavioral
                                      }
                                      error={error?.message}
                                      placeholder='Input behavioral here'
                                      title='Behavioral'
                                      showTitle={true}
                                      onChange={onChange}
                                    />
                                  );
                                }}
                              />

                              <Controller
                                control={control}
                                name={`selected_audience_archetypes.${index}.onepage_content.audience_insights.current_trends`}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => {
                                  return (
                                    <CardInput
                                      isEditing={isEditing}
                                      value={value?.toString()}
                                      defaultValue={
                                        item.onepage_content.audience_insights
                                          .current_trends
                                      }
                                      error={error?.message}
                                      placeholder='Input current trends here'
                                      title='Current Trends'
                                      showTitle={true}
                                      onChange={onChange}
                                    />
                                  );
                                }}
                              />

                              <Controller
                                control={control}
                                name={`selected_audience_archetypes.${index}.onepage_content.audience_insights.audience_insights`}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => {
                                  return (
                                    <CardInput
                                      isEditing={isEditing}
                                      value={value?.toString()}
                                      defaultValue={
                                        item.onepage_content.audience_insights
                                          .audience_insights
                                      }
                                      error={error?.message}
                                      placeholder='Input audience insights here'
                                      title='Audience Insights'
                                      showTitle={true}
                                      onChange={onChange}
                                    />
                                  );
                                }}
                              />

                              <Controller
                                control={control}
                                name={`selected_audience_archetypes.${index}.onepage_content.audience_insights.cultural_insights`}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => {
                                  return (
                                    <CardInput
                                      isEditing={isEditing}
                                      value={value?.toString() ?? ""}
                                      defaultValue={
                                        item.onepage_content.audience_insights
                                          .cultural_insights
                                      }
                                      error={error?.message}
                                      placeholder='Input cultural insights here'
                                      viewPlaceholder='Click edit to add cultural insights'
                                      title='Cultural Insights'
                                      showTitle={true}
                                      onChange={onChange}
                                    />
                                  );
                                }}
                              />
                            </div>
                          </Fragment>
                        ),
                      )
                    : Array.from({ length: 1 }).map((_, index) => (
                        <Fragment key={index}>
                          <div className='w-65 h-20 animate-pulse bg-soft-purple-redx rounded-full  mb-14' />
                          <div
                            className={`grid ${gridColumnClass} gap-x-24 gap-y-15 pb-8`}>
                            <CardInput
                              isEditing={isEditing}
                              value={""}
                              defaultValue={""}
                              isFetching={true}
                              placeholder='Input pain point here'
                              title='Pain Point'
                              showTitle={true}
                              onChange={() => {}}
                            />

                            <CardInput
                              isEditing={isEditing}
                              value={""}
                              defaultValue={""}
                              isFetching={true}
                              placeholder='Input life style here'
                              title='Life Stye'
                              showTitle={true}
                              onChange={() => {}}
                            />

                            <CardInput
                              isEditing={isEditing}
                              value={""}
                              defaultValue={""}
                              isFetching={true}
                              placeholder='Input behavioral here'
                              title='Behavioral'
                              showTitle={true}
                              onChange={() => {}}
                            />

                            <CardInput
                              isEditing={isEditing}
                              value={""}
                              defaultValue={""}
                              isFetching={true}
                              placeholder='Input current Trends here'
                              title='Current Trends'
                              showTitle={true}
                              onChange={() => {}}
                            />

                            <CardInput
                              isEditing={isEditing}
                              value={""}
                              defaultValue={""}
                              isFetching={true}
                              placeholder='Input audience insights here'
                              title='Audience Insights'
                              showTitle={true}
                              onChange={() => {}}
                            />

                            <CardInput
                              isEditing={isEditing}
                              value={""}
                              defaultValue={""}
                              isFetching={true}
                              placeholder='Input cultural insights here'
                              title='Cultural Insights'
                              showTitle={true}
                              onChange={() => {}}
                            />
                          </div>
                        </Fragment>
                      ))}
                </Fragment>
              )}
            </SectionCard>

            <SectionCard
              isEditor={isEditor}
              disabledSave={disabledSave || isPending}
              title='Key Highlight'
              cols={2}
              isFetching={isLoading || isOnePageStrategy.isQuerying}
              onCancelEdit={handleCancelEditSection}
              onSubmitEdit={handleSubmit(onSubmit)}>
              {({ isEditing, gridColumnClass }) => (
                <Fragment>
                  {!isLoading && !isOnePageStrategy.isQuerying
                    ? parsedData.selected_audience_archetypes.map(
                        (item, index) => {
                          return (
                            <Fragment key={index}>
                              <h3 className='text-15 font-bold pb-4 text-blue-redx'>
                                {`Archetype ${index + 1} - ${
                                  item.archetype_name || ""
                                }`}
                              </h3>
                              <div
                                className={`grid ${gridColumnClass} gap-x-24 gap-y-15 pb-8`}>
                                <Controller
                                  control={control}
                                  name={`selected_audience_archetypes.${index}.onepage_content.key_highlight.emotional`}
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                  }) => {
                                    return (
                                      <CardInput
                                        isEditing={isEditing}
                                        value={value?.toString()}
                                        defaultValue={
                                          item.onepage_content.key_highlight
                                            ?.emotional
                                        }
                                        error={error?.message}
                                        placeholder='Input emotional here'
                                        title='Emotional'
                                        showTitle={true}
                                        isFetching={
                                          isLoading ||
                                          isOnePageStrategy.isQuerying
                                        }
                                        onChange={onChange}
                                      />
                                    );
                                  }}
                                />
                                <Controller
                                  control={control}
                                  name={`selected_audience_archetypes.${index}.onepage_content.key_highlight.functional`}
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                  }) => {
                                    return (
                                      <CardInput
                                        isEditing={isEditing}
                                        value={value?.toString()}
                                        defaultValue={
                                          item.onepage_content.key_highlight
                                            ?.functional
                                        }
                                        error={error?.message}
                                        placeholder='Input functional here'
                                        title='Functional'
                                        showTitle={true}
                                        isFetching={
                                          isLoading ||
                                          isOnePageStrategy.isQuerying
                                        }
                                        onChange={onChange}
                                      />
                                    );
                                  }}
                                />
                              </div>
                            </Fragment>
                          );
                        },
                      )
                    : Array.from({ length: 1 }).map((_, index) => (
                        <Fragment key={index}>
                          <div className='w-65 h-20 animate-pulse bg-soft-purple-redx rounded-full  mb-14' />
                          <div
                            className={`grid ${gridColumnClass} gap-x-24 gap-y-15 pb-8`}>
                            <CardInput
                              isEditing={isEditing}
                              value={""}
                              defaultValue={""}
                              isFetching={true}
                              placeholder='Input Emotional here'
                              title='Emotional'
                              showTitle={true}
                              onChange={() => {}}
                            />

                            <CardInput
                              isEditing={isEditing}
                              value={""}
                              defaultValue={""}
                              isFetching={true}
                              placeholder='Input Functional here'
                              title='Functional'
                              showTitle={true}
                              onChange={() => {}}
                            />
                          </div>
                        </Fragment>
                      ))}
                </Fragment>
              )}
            </SectionCard>

            <SectionCard
              isEditor={isEditor}
              disabledSave={disabledSave || isPending}
              title='Product Feature Benefit'
              cols={1}
              isFetching={isLoading || isOnePageStrategy.isQuerying}
              onCancelEdit={handleCancelEditSection}
              onSubmitEdit={handleSubmit(onSubmit)}>
              {({ isEditing }) => (
                <Fragment>
                  {!isLoading && !isOnePageStrategy.isQuerying
                    ? parsedData.selected_audience_archetypes.map(
                        (item, index) => (
                          <Fragment key={index}>
                            <h3 className='text-15 font-bold pb-4 text-blue-redx'>
                              {`Archetype ${index + 1} - ${
                                item.archetype_name || ""
                              }`}
                            </h3>

                            <Controller
                              control={control}
                              name={`selected_audience_archetypes.${index}.onepage_content.product_feature_benefit`}
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => {
                                return (
                                  <CardInput
                                    isEditing={isEditing}
                                    value={value?.toString()}
                                    defaultValue={
                                      item.onepage_content
                                        .product_feature_benefit
                                    }
                                    error={error?.message}
                                    placeholder='Input product feature benefit here'
                                    title='Product Feature Benefit'
                                    showTitle={true}
                                    isFetching={
                                      isLoading || isOnePageStrategy.isQuerying
                                    }
                                    onChange={onChange}
                                  />
                                );
                              }}
                            />
                          </Fragment>
                        ),
                      )
                    : Array.from({ length: 1 }).map((_, index) => (
                        <Fragment key={index}>
                          <div className='w-65 h-20 animate-pulse bg-soft-purple-redx rounded-full  mb-14' />
                          <CardInput
                            isEditing={isEditing}
                            value={""}
                            defaultValue={""}
                            isFetching={isLoading}
                            placeholder='Input Product Feature Benefit here'
                            title='Product Feature Benefit'
                            showTitle={true}
                            onChange={() => {}}
                          />
                        </Fragment>
                      ))}
                </Fragment>
              )}
            </SectionCard>

            <SectionCard
              isEditor={isEditor}
              disabledSave={disabledSave || isPending}
              title='Strategic Statement'
              cols={1}
              isFetching={isLoading || isOnePageStrategy.isQuerying}
              onCancelEdit={handleCancelEditSection}
              onSubmitEdit={handleSubmit(onSubmit)}>
              {({ isEditing, gridColumnClass }) => (
                <Fragment>
                  {!isLoading && !isOnePageStrategy.isQuerying
                    ? parsedData.selected_audience_archetypes.map(
                        (item, index) => (
                          <Fragment key={index}>
                            <h3 className='text-15 font-bold pb-4 text-blue-redx'>
                              {`Archetype ${index + 1} - ${
                                item.archetype_name || ""
                              }`}
                            </h3>
                            <div
                              className={`grid ${gridColumnClass} gap-x-24 gap-y-15 pb-8`}>
                              <Controller
                                control={control}
                                name={`selected_audience_archetypes.${index}.onepage_content.strategic_statement`}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => {
                                  return (
                                    <CardInput
                                      isEditing={isEditing}
                                      value={value?.toString()}
                                      defaultValue={
                                        item.onepage_content.strategic_statement
                                      }
                                      error={error?.message}
                                      placeholder='Input strategic statement here'
                                      title='Strategic Statement'
                                      showTitle={true}
                                      onChange={onChange}
                                    />
                                  );
                                }}
                              />
                            </div>
                          </Fragment>
                        ),
                      )
                    : Array.from({ length: 1 }).map((_, index) => (
                        <Fragment key={index}>
                          <div className='w-65 h-20 animate-pulse bg-soft-purple-redx rounded-full  mb-14' />
                          <div
                            className={`grid ${gridColumnClass} gap-x-24 gap-y-15 pb-8`}>
                            <CardInput
                              isEditing={isEditing}
                              value={""}
                              defaultValue={""}
                              isFetching={true}
                              placeholder='Input Strategic Statement here'
                              title='Strategic Statement'
                              showTitle={true}
                              onChange={() => {}}
                            />
                          </div>
                        </Fragment>
                      ))}
                </Fragment>
              )}
            </SectionCard>

            <SectionCard
              isEditor={isEditor}
              disabledSave={disabledSave || isPending}
              title='Desired Response'
              cols={2}
              isFetching={isLoading || isOnePageStrategy.isQuerying}
              onCancelEdit={handleCancelEditSection}
              onSubmitEdit={handleSubmit(onSubmit)}>
              {({ isEditing, gridColumnClass }) => (
                <Fragment>
                  {!isLoading && !isOnePageStrategy.isQuerying
                    ? parsedData.selected_audience_archetypes.map(
                        (item, index) => (
                          <Fragment key={index}>
                            <h3 className='text-15 font-bold pb-4 text-blue-redx'>
                              {`Archetype ${index + 1} - ${
                                item.archetype_name || ""
                              }`}
                            </h3>
                            <div
                              className={`grid ${gridColumnClass} gap-x-24 gap-y-15 pb-8`}>
                              <Controller
                                control={control}
                                name={`selected_audience_archetypes.${index}.onepage_content.desired_response.before`}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => {
                                  return (
                                    <CardInput
                                      isEditing={isEditing}
                                      value={value?.toString()}
                                      defaultValue={
                                        item.onepage_content.desired_response
                                          .before
                                      }
                                      isFetching={isLoading}
                                      error={error?.message}
                                      placeholder='Input before here'
                                      title='Before'
                                      showTitle={true}
                                      onChange={onChange}
                                    />
                                  );
                                }}
                              />
                              <Controller
                                control={control}
                                name={`selected_audience_archetypes.${index}.onepage_content.desired_response.after`}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => {
                                  return (
                                    <CardInput
                                      isEditing={isEditing}
                                      value={value?.toString()}
                                      defaultValue={
                                        item.onepage_content.desired_response
                                          .after
                                      }
                                      isFetching={isLoading}
                                      error={error?.message}
                                      placeholder='Input after here'
                                      title='After'
                                      showTitle={true}
                                      onChange={onChange}
                                    />
                                  );
                                }}
                              />
                            </div>
                          </Fragment>
                        ),
                      )
                    : Array.from({ length: 1 }).map((_, index) => (
                        <Fragment key={index}>
                          <div className='w-65 h-20 animate-pulse bg-soft-purple-redx rounded-full  mb-14' />
                          <div
                            className={`grid ${gridColumnClass} gap-x-24 gap-y-15 pb-8`}>
                            <CardInput
                              isEditing={isEditing}
                              value={""}
                              defaultValue={""}
                              isFetching={isLoading}
                              placeholder='Input Before here'
                              title='Before'
                              showTitle={true}
                              onChange={() => {}}
                            />
                            <CardInput
                              isEditing={isEditing}
                              value={""}
                              defaultValue={""}
                              isFetching={isLoading}
                              placeholder='Input After here'
                              title='After'
                              showTitle={true}
                              onChange={() => {}}
                            />
                          </div>
                        </Fragment>
                      ))}
                </Fragment>
              )}
            </SectionCard>

            <button
              type='submit'
              className='hidden'>
              Save
            </button>
          </form>
        </div>
      )}
      <SelectingFunnel
        isOpen={modalFramework}
        onClose={() => setModalFramework(false)}
        project={project}
      />

      <RegenerateModal
        isRegenerating={isRegeneratingModal}
        isOpen={showRegenerateModal}
        handleSubmit={handleRegenerateKeyTouchPoint}
        onClose={() => setShowRegenerateModal(false)}
      />

      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </Fragment>
  );
};

export default Index;
