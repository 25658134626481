import React, {
  useState,
  useEffect,
  Fragment,
  useContext,
  useRef,
} from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Schema } from "@/Types/creative-idea-alignment/schema";
import { ProjectContext } from "@/Context/ProjectContext";
import {
  saveRouteNext,
  saveRoutePrev,
  saveInformation,
  saveSubInformation,
  setEnableHistoryStatus,
} from "@/Context/actions/projectActions";
import Card from "./Partials/Card";
import RegenerateButton from "@/Components/Projects/RegenerateButton";
import EditButton from "@/Components/Projects/EditButton";
import GenerateLoading from "@/Components/GenerateLoading";
import SliderContainer from "@/Components/Projects/SliderContainer";
import SourcesList from "@/Components/Projects/SourcesList";

import { useGenerate } from "@/Context/hooks/useGenerate";
import useRolesBadge from "@/Hooks/useRolesBadge";

import { RouteOption } from "@/Context/reducer/projectReducer";

import { IdeaAlignmentList } from "@/Types/creative-idea-alignment";
import { ProjectProps, SourcesType } from "@/Types/projects";
import { progressPortion } from "@/Utils/dispatcher";

import useHistoryStatus from "@/Hooks/react-query/useHistoryStatus";
import useUser from "@/Hooks/react-query/useUser";
import RegenerateInfoModal from "@/Components/Modals/RegenerateInfoModal";
import { SectionList } from "@/Types/tabs";
import { useQueryClient } from "@tanstack/react-query";
import RegenerateModal from "@/Components/Modals/RegenerateModal";
import toast from "react-hot-toast";
import ErrorLLM from "@/Components/Toast/ErrorLLM";
import SectionInformation from "@/Components/Projects/SectionInformation";
import useIdeaAlignments from "@/Hooks/react-query/idea-alignment/useIdeaAlignments";
import { useSubmitIdeaAlignment } from "@/Hooks/react-query/idea-alignment/useSubmit";
import useDifferentArchetypes from "@/Hooks/react-query/audience-archetype/useDifferentArchetypes";
import ViewInfoModal from "@/Components/Modals/ViewInfoModal";

export type CardHandles = {
  submitForm: () => void;
};

const Index: React.FC<{ project: ProjectProps }> = ({ project }) => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const [showViewInfoModal, setShowViewInfoModal] = useState(false);
  const [state, dispatch] = useContext(ProjectContext);
  const [isRegenerating, setIsReGenerating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showAlertGenerateModal, setShowAlertGenerateModal] = useState(false);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [parsedData, setParsedData] = useState<IdeaAlignmentList[]>([]);
  const [editedData, setEditedData] = useState<IdeaAlignmentList[]>([]);
  const [sources, setSources] = useState<SourcesType[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number[]>([]);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [totalRegenerate, setTotalRegenerate] = useState<number>(0);
  const [isGenerate, setIsGenerate] = useState(false);
  const [goToIndex, setGoToIndex] = useState<number | undefined>(undefined);
  const [selectedEditedData, setSelectedEditedData] =
    useState<IdeaAlignmentList>();
  const cardRefs = useRef<(CardHandles | null)[]>([]);
  const [totalSelectedIdeaAlignment, setTotalSelectedIdeaAlignment] =
    useState<number>(0);
  const [totalIdeaAlignment, setTotalIdeaAlignment] = useState<number>(0);

  const { data: user } = useUser();

  const { roles } = useRolesBadge(project, user);
  const { handleGenerateIdeaAlignment, handleGenerateIdeaPersonalization } =
    useGenerate(dispatch);
  const {
    data,
    isLoading,
    refetch,
    isRefetching,
    isFetched,
    isPending: isePendingRefetch,
  } = useIdeaAlignments(project.slug);

  const { data: differentArchetypes, isPending: isPendingDiffArch } =
    useDifferentArchetypes(project);

  const { mutate, isPending } = useSubmitIdeaAlignment();

  const {
    isIdeaAlignment,
    isIdeaPersonalization,
    isUpdateState: isPendingHistoryStatus,
    refetch: refetchHistory,
  } = useHistoryStatus(project, state.isEnabledHistoryStatus);

  const isEditor = roles.includes("Owner") || roles.includes("Creative");

  const toggleIsEditing = () => setIsEditing(!isEditing);
  const toggleIsAdding = () => setIsAdding(!isAdding);

  const routeOptionValue: RouteOption = {
    label: "Generate Idea Personalization",
    isActive: true,
    isGenerate: true,
    isDisabled: true,
  };

  const handleCancelEdit = () => {
    setParsedData(editedData);
    toggleIsEditing();
  };

  const handleFetchSubmit = async (
    payload: Schema,
    id: number,
    index: number,
  ) => {
    const projectSlug = project.slug;

    mutate(
      { payload: payload, projectSlug, id },
      {
        onSuccess: () => {
          isAdding &&
            setTimeout(() => {
              setGoToIndex(index);
            }, 1000);

          isAdding && toggleIsAdding();
          isEditing && toggleIsEditing();

          setTimeout(() => {
            refetch();
          }, 700);
        },
      },
    );
  };

  const handleRegenerateButton = async (data: { prompt: string }) => {
    dispatch(saveSubInformation(""));
    setParsedData([]);
    setEditedData([]);
    setIsGenerate(true);
    await handleGenerateIdeaAlignment()(project.slug, data.prompt);
  };

  const onSelectSuccess = async () => {
    refetch().then((data) => {
      const idea = data.data;
      if (idea) {
        refetchHistory().then((data) => {
          const histories = data.data;
          if (histories) {
            const { data: history } = histories;
            if (
              history.idea_personalization.isRegenerated &&
              idea.data.total_selected_idea_alignment
            ) {
              setShowAlertGenerateModal(true);
            }
          }
        });
      }
    });
  };

  const handleSubmit = () => {
    cardRefs.current.forEach((ref, index) => {
      if (ref && index === activeIndex) {
        ref.submitForm();
      }
    });
  };

  const handleRegenerate = async () => {
    setIsReGenerating(true);
    await handleGenerateIdeaPersonalization()(project.slug, null);
    setIsReGenerating(false);
    navigate(`/${project.slug}/idea-personalization`);
  };

  useEffect(() => {
    if (data && data.data) {
      const { lists, sources, total_regenerate, is_querying } = data.data;
      sources && setSources(sources);
      total_regenerate && setTotalRegenerate(total_regenerate);
      is_querying && setIsGenerate(true);

      if (lists && lists.length && !is_querying) {
        setParsedData(lists);
        setEditedData(lists);
        setIsGenerate(false);
      } else {
        setParsedData([]);
        setEditedData([]);
      }
    }

    const totalIdea = data?.data?.total_idea_alignment || 0;
    const totalSelected = data?.data?.total_selected_idea_alignment || 0;

    setTotalIdeaAlignment(totalIdea);
    setTotalSelectedIdeaAlignment(totalSelected);

    dispatch(saveSubInformation(""));
  }, [data, isIdeaAlignment.isQuerying]);

  useEffect(() => {
    if (isIdeaAlignment.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }

    if (parsedData?.length) {
      const selectedDataWithIndex = parsedData
        .map((item, index) => ({ item, index })) // Map to array of objects with item and index
        .filter(({ item }) => item.is_selected);

      const indices = selectedDataWithIndex.map(({ index }) => index);
      setSelectedIndex(indices);
    }

    if (!isGenerate && !isIdeaAlignment.status && !isIdeaAlignment.isQuerying) {
      setIsGenerate(true);
      handleGenerateIdeaAlignment()(project.slug);
    }
  }, [isIdeaAlignment, isGenerate, parsedData]);

  useEffect(() => {
    !isIdeaAlignment.isQuerying && !isPendingHistoryStatus && refetch();
  }, [isGenerate, isIdeaAlignment.isQuerying, isPendingHistoryStatus]);

  useEffect(() => {
    dispatch(saveRouteNext(routeOptionValue));
    dispatch(
      saveRoutePrev({
        label: "Back",
        isActive: true,
        onClick: () => navigate(`/${project.slug}/creative-idea`),
      }),
    );
  }, []);

  useEffect(() => {
    if (!isEditor) {
      dispatch(
        saveRouteNext({
          ...routeOptionValue,
          isInactive:
            !isIdeaPersonalization.status || isIdeaPersonalization.isQuerying,
          isDisabled: false,
          label: "Next to Idea Personalization",
          isGenerate: false,
          onClick: () => {
            if (isIdeaAlignment.isQuerying) {
              dispatch(
                saveSubInformation(
                  `{text-error-redx} Please wait, idea alignment is generating`,
                ),
              );
              return;
            }

            if (!isIdeaPersonalization.status) {
              setShowViewInfoModal(true);
              return;
            }

            navigate(`/${project.slug}/idea-personalization`);
          },
        }),
      );
      return;
    }
    const count = totalSelectedIdeaAlignment || 0;

    if (count === 0) {
      dispatch(saveInformation(`No idea alignment is selected`));
      dispatch(
        saveRouteNext({
          ...routeOptionValue,
          isInactive: true,
          isDisabled: false,
          label:
            isIdeaPersonalization.status &&
            (differentArchetypes?.is_different_archetype
              .idea_personalizations ||
              isIdeaPersonalization.isRegenerated)
              ? "Regenerate Idea Personalization"
              : "Generate Idea Personalization",
          onClick: () => {
            if (
              isIdeaAlignment.isQuerying ||
              isGenerate ||
              !isIdeaAlignment.status
            ) {
              dispatch(
                saveSubInformation(
                  `{text-error-redx} Please wait, idea alignment is generating`,
                ),
              );
              return;
            }
            dispatch(
              saveSubInformation(
                `{text-error-redx} Please select 1 idea alignment`,
              ),
            );
          },
        }),
      );
      return;
    } else {
      dispatch(saveInformation(`Idea alignment is selected`));
      dispatch(saveSubInformation(``));
    }

    if (!isPendingHistoryStatus && !isPendingDiffArch) {
      if (
        isIdeaPersonalization.status &&
        !isIdeaPersonalization.isRegenerated &&
        !differentArchetypes?.is_different_archetype.idea_personalizations
      ) {
        dispatch(
          saveRouteNext({
            ...routeOptionValue,
            isInactive:
              isGenerate ||
              isIdeaAlignment.isQuerying ||
              !isIdeaAlignment.status
                ? true
                : false,
            isGenerate: false,
            label: "Next to Idea Personalization",
            isDisabled: count === 0,
            onClick: () => {
              if (isIdeaAlignment.isQuerying) {
                dispatch(
                  saveSubInformation(
                    `{text-error-redx} Please wait, idea alignment is generating`,
                  ),
                );
                return;
              }

              if (isIdeaPersonalization.isQuerying) {
                dispatch(
                  saveSubInformation(
                    `{text-error-redx} Please wait, idea personalization is still generating`,
                  ),
                );

                setTimeout(() => {
                  dispatch(saveSubInformation(""));
                }, 3000);
                return;
              }

              queryClient.resetQueries({
                queryKey: ["ideaPersonalization", project.slug],
                exact: true,
              });
              navigate(`/${project.slug}/idea-personalization`);
            },
          }),
        );
        return;
      } else if (
        isIdeaPersonalization.status &&
        (differentArchetypes?.is_different_archetype.idea_personalizations ||
          isIdeaPersonalization.isRegenerated)
      ) {
        dispatch(
          saveRouteNext({
            ...routeOptionValue,
            isInactive:
              isGenerate ||
              isIdeaAlignment.isQuerying ||
              !isIdeaAlignment.status
                ? true
                : false,
            isGenerate: true,
            isDisabled: count === 0,
            label: "Regenerate Idea Personalization",
            onClick: () => {
              if (
                isIdeaAlignment.isQuerying ||
                isGenerate ||
                !isIdeaAlignment.status
              ) {
                dispatch(
                  saveSubInformation(
                    `{text-error-redx} Please wait, idea alignment is generating`,
                  ),
                );
                return;
              }

              if (isIdeaPersonalization.isQuerying) {
                dispatch(
                  saveSubInformation(
                    `{text-error-redx} Please wait, idea personalization is still generating`,
                  ),
                );

                setTimeout(() => {
                  dispatch(saveSubInformation(""));
                }, 3000);
                return;
              }

              setShowRegenerateModal(true);
            },
          }),
        );
        return;
      }

      dispatch(
        saveRouteNext({
          ...routeOptionValue,
          isInactive:
            isGenerate || isIdeaAlignment.isQuerying || !isIdeaAlignment.status
              ? true
              : false,
          isGenerate: true,
          isDisabled: count === 0,
          label: "Generate Idea Personalization",
          onClick: async () => {
            if (isIdeaAlignment.isQuerying) {
              dispatch(
                saveSubInformation(
                  `{text-error-redx} Please wait, idea alignment is generating`,
                ),
              );
              return;
            }

            if (isIdeaPersonalization.isQuerying) {
              dispatch(
                saveSubInformation(
                  `{text-error-redx} Please wait, idea personalization is still generating`,
                ),
              );

              setTimeout(() => {
                dispatch(saveSubInformation(""));
              }, 3000);
              return;
            }

            if (isAdding || isEditing) {
              dispatch(
                saveSubInformation(`{text-error-redx} Please do save first`),
              );

              setTimeout(() => {
                dispatch(saveSubInformation(""));
              }, 3000);
              return;
            }

            handleGenerateIdeaPersonalization()(project.slug, null);
            navigate(`/${project.slug}/idea-personalization`);
          },
        }),
      );
    }
  }, [
    isAdding,
    isEditing,
    isIdeaAlignment,
    isIdeaPersonalization,
    isPendingHistoryStatus,
    selectedIndex,
    totalSelectedIdeaAlignment,
    totalIdeaAlignment,
    isPendingDiffArch,
    differentArchetypes,
    isGenerate,
    isEditor,
  ]);

  const sectionList: SectionList = {
    title: "idea alignment",
    value: "alignment",
    section: "idea",
  };

  useEffect(() => {
    dispatch(setEnableHistoryStatus(!(isAdding || isEditing)));
  }, [isAdding, isEditing]);

  return (
    <Fragment>
      <Helmet>
        <title>Idea Alignment</title>
      </Helmet>
      {isGenerate ? (
        <GenerateLoading
          progress={progressPortion({
            progress: Number((state.ideaAlignment.progress / 2).toFixed(0)),
            isQuerying: isIdeaAlignment.isQuerying,
            isContentAvailable: parsedData?.length > 0,
          })}
          project={project}
          section={sectionList}
        />
      ) : (
        <div className='w-full h-full'>
          <div className='flex md:flex-col flex-row items-center justify-between mb-24 gap-16 sticky top-56 z-20 bg-white py-16'>
            <div className='flex flex-col grow'>
              <h1 className='text-25 font-bold mb-8 leading-none inline-flex items-center gap-12'>
                Idea Alignment
                <SectionInformation
                  title='What did Ainstein do with Idea Alignment?'
                  description='Ainstein will align your initial idea with Target Audience Insight, Cultural Insight, Project Objective, and Competitor Analysis.'
                />
              </h1>
              <span className='text-1620 font-normal text-grey-redx'>
                Please{" "}
                <span className='font-bold text-black-redx'>
                  select one idea{" "}
                </span>{" "}
                , edit, or add by clicking 'Add Idea Manually'.
              </span>
            </div>
            {isEditor && (
              <div className='flex items-center'>
                <RegenerateButton
                  isGenerateMore
                  limit={totalRegenerate}
                  maxLimit={project.max_generated_data} // to do ganti ke max limit
                  onSubmit={handleRegenerateButton}
                />
              </div>
            )}
          </div>
          {!isLoading && !isIdeaAlignment.isQuerying ? (
            <SliderContainer
              isEditing={isEditing}
              isAdding={isAdding}
              length={parsedData.length}
              selectedIndexes={selectedIndex}
              goToIndex={goToIndex}
              onActiveIndex={(index) => setActiveIndex(index)}>
              {parsedData.map((ideaAlignment, index) => (
                <Card
                  project={project}
                  id={ideaAlignment.id ?? 0}
                  totalIdeaAlignment={parsedData.length}
                  key={index}
                  isFetching={isRefetching}
                  ideaAlignment={
                    ideaAlignment?.idea_alignment_content ?? {
                      idea_title: "",
                      idea_description: "",
                      reason: "",
                    }
                  }
                  index={index}
                  isEditing={isEditing && isEditor}
                  isEditor={isEditor}
                  isSelected={!!ideaAlignment.is_selected}
                  isEdited={ideaAlignment?.is_edit ?? false}
                  onSelectSuccess={onSelectSuccess}
                  selectedIdea={selectedEditedData}
                  activeIndex={activeIndex}
                  ref={(el) => (cardRefs.current[index] = el)}
                  onSubmitSuccess={handleFetchSubmit}
                />
              ))}

              {/* Add a new card for adding new archetype if the user is in editor mode */}
              {isEditor && isAdding && (
                <Card
                  project={project}
                  id={-1}
                  isEditor={isEditor}
                  totalIdeaAlignment={parsedData.length + 1}
                  ideaAlignment={{
                    idea_title: "", // Initialize empty fields for the new card
                    idea_description: "",
                    reason: "",
                  }}
                  activeIndex={activeIndex}
                  index={parsedData.length}
                  isEditing={isAdding}
                  isSelected={false}
                  isEdited={false}
                  selectedIdea={selectedEditedData}
                  ref={(el) => (cardRefs.current[parsedData.length] = el)}
                  onSubmitSuccess={handleFetchSubmit}
                  onSelectSuccess={onSelectSuccess}
                />
              )}
            </SliderContainer>
          ) : (
            <Card
              project={project}
              id={-1}
              isEditor={isEditor}
              totalIdeaAlignment={parsedData.length + 1}
              ideaAlignment={{
                idea_title: "", // Provide initial values for the fallback card
                idea_description: "",
                reason: "",
              }}
              isFetching={true}
              activeIndex={activeIndex}
              index={-1}
              isEdited={false}
              isEditing={isAdding}
              onSelectSuccess={onSelectSuccess}
              isSelected={false}
              selectedIdea={undefined}
              ref={(el) => (cardRefs.current[parsedData.length] = el)}
              onSubmitSuccess={handleFetchSubmit}
            />
          )}

          <div className='flex w-full justify-between items-center pt-12 mb-35'>
            {isEditor &&
              (!isAdding && !isEditing ? (
                <button
                  className='text-15 font-semibold inline-flex text-blue-redx gap-x-4'
                  onClick={toggleIsAdding}>
                  Add Idea Alignment Manually
                  <Icon
                    icon='lucide:plus'
                    className='w-20 h-20'
                  />
                </button>
              ) : (
                <div></div>
              ))}
            <div className='flex justify-end items-center gap-x-15'>
              {isEditor &&
                (isEditing || isAdding ? (
                  <Fragment>
                    <button
                      className='inline-flex items-center gap-x-4 text-error-redx cursor-pointer'
                      onClick={isAdding ? toggleIsAdding : handleCancelEdit}>
                      <span className='text-15 font-semibold'>Cancel</span>
                      <Icon
                        icon='lucide:x'
                        className='w-20 h-20'
                      />
                    </button>
                    <button
                      disabled={isPending}
                      className={`inline-flex items-center gap-x-4 ${
                        isPending ? "text-gray-600" : "text-blue-redx"
                      } cursor-pointer`}
                      onClick={handleSubmit}>
                      <span className='text-15 font-semibold'>Save</span>
                      <Icon
                        icon='lucide:save'
                        className='w-20 h-20'
                      />
                    </button>
                  </Fragment>
                ) : (
                  <EditButton
                    toggleEditing={() => {
                      const currData = editedData[activeIndex];
                      if (currData) {
                        setSelectedEditedData(currData);
                        toggleIsEditing();
                      }
                    }}
                  />
                ))}
            </div>
          </div>
          <div className='flex w-full pb-60 mr-1'>
            {!isLoading && sources && <SourcesList sources={sources} />}
            {isLoading && (
              <div className='inline-flex gap-10'>
                {Array.from({ length: 3 }).map((_, index) => (
                  <div
                    key={index}
                    className='min-w-363 h-65 flex items-center px-15 py-12 gap-x-12 border-1 border-soft-purple-redx rounded-10 full animate-pulse bg-soft-purple-redx'
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      )}

      <RegenerateInfoModal
        isOpen={showAlertGenerateModal}
        onClose={() => {
          setShowAlertGenerateModal(false);
        }}
      />

      <RegenerateModal
        isRegenerating={isRegenerating}
        isOpen={showRegenerateModal}
        handleSubmit={handleRegenerate}
        onClose={() => setShowRegenerateModal(false)}
      />
      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </Fragment>
  );
};

export default Index;
